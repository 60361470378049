import { FC, useEffect, useState } from 'react';
import { EquipmentAudit } from '../../types';
import { StatusAudit } from '../../enums';
import {
  ResultBlock,
  ResultBlockBody,
  ResultBlockCheck,
  ResultBlockHead,
  ResultBlockTitle,
  ResultTextColumn,
  ResultLine,
  ResultLineAuditorCompany,
  ResultName,
  ResultText,
  ResultLineAuditorCompanyMobile,
  ResultTextWrapper,
} from '../style';


interface TestResultsProps {
  equipmentAudit: EquipmentAudit;
}

const TestResults: FC<TestResultsProps> = ({ equipmentAudit }) => {
  const [status, setStatus] = useState<StatusAudit>(StatusAudit.Green);
  const [text, setText] = useState<string>('');

  useEffect(() => {
    const timeDifference = Math.floor(
      (new Date(equipmentAudit.nextAuditDate).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24),
    );

    if (equipmentAudit.passed && timeDifference < 0) {
      setStatus(StatusAudit.Red);
      setText('Durchgefallen, Prüfzeitraum abgelaufen');
    } else if (equipmentAudit.passed && timeDifference > 90) {
      setStatus(StatusAudit.Green);
      setText('Bestanden');
    } else if (equipmentAudit.passed && timeDifference < 90) {
      setStatus(StatusAudit.Yellow);
      setText('Bestanden, läuft bald aus');
    } else if (!equipmentAudit.passed) {
      setStatus(StatusAudit.Red);
      setText('Durchgefallen');
    }
  }, [equipmentAudit]);

  return (
    <ResultBlock>
      <ResultBlockHead isFlex={false}>
        <ResultBlockTitle>Prüfergebnis</ResultBlockTitle>
        <ResultBlockCheck status={status}>{text}</ResultBlockCheck>
      </ResultBlockHead>
      <ResultBlockBody>
        <ResultLine>
          <ResultName>Prüfdatum</ResultName>
          <ResultText>{new Date(equipmentAudit.auditDate).toLocaleDateString()}</ResultText>
        </ResultLine>
        <ResultLine>
          <ResultName>Nächste Prüfung</ResultName>
          <ResultText>{new Date(equipmentAudit.nextAuditDate).toLocaleDateString()}</ResultText>
        </ResultLine>
        <ResultLine>
          <ResultName>Prüfvorschrift</ResultName>
          <ResultText>{equipmentAudit.standard}</ResultText>
        </ResultLine>

        {equipmentAudit.values.map((v, index) => (
          <ResultLineAuditorCompanyMobile key={`${index}_value`}>
            <ResultName>{index === 0 ? 'Prüfungsdaten' : ''}</ResultName>
            <ResultTextWrapper>
              <ResultTextColumn>{v.name}</ResultTextColumn>
              <ResultTextColumn>
                {v.isImage ? <img src={v.values[0]} alt="Prüfdienstleister" /> : v.values[0]}
              </ResultTextColumn>
            </ResultTextWrapper>
          </ResultLineAuditorCompanyMobile>
        ))}

        <ResultLineAuditorCompany>
          <ResultName>Prüfdienstleister</ResultName>
          <ResultText>{equipmentAudit.auditorCompany.name}</ResultText>
        </ResultLineAuditorCompany>

        {equipmentAudit.auditorCompany.phone && (
          <ResultLineAuditorCompany>
            <ResultName></ResultName>
            <ResultText>{equipmentAudit.auditorCompany.phone}</ResultText>
          </ResultLineAuditorCompany>
        )}

        <ResultLineAuditorCompany>
          <ResultName></ResultName>
          <ResultText>
            {equipmentAudit.auditorCompany.street} {equipmentAudit.auditorCompany.house}
          </ResultText>
        </ResultLineAuditorCompany>

        <ResultLineAuditorCompany>
          <ResultName></ResultName>
          <ResultText>
            {equipmentAudit.auditorCompany.zip} {equipmentAudit.auditorCompany.city}
          </ResultText>
        </ResultLineAuditorCompany>

        <ResultLineAuditorCompany>
          <ResultName></ResultName>
          <ResultText>{equipmentAudit.auditorCompany.country}</ResultText>
        </ResultLineAuditorCompany>

        {equipmentAudit.auditorCompany.email && (
          <ResultLineAuditorCompany>
            <ResultName></ResultName>
            <ResultText>{equipmentAudit.auditorCompany.email}</ResultText>
          </ResultLineAuditorCompany>
        )}

        {equipmentAudit.auditorCompany.logo !== null && (
          <ResultLineAuditorCompany>
            <ResultName></ResultName>
            <ResultText>
              <img src={equipmentAudit.auditorCompany.logo} alt="Prüfdienstleister" />
            </ResultText>
          </ResultLineAuditorCompany>
        )}
      </ResultBlockBody>
    </ResultBlock>
  );
};

export default TestResults;
