/* eslint-disable array-callback-return */
import CompanyInfo from './components/companyInfo/companyInfo';
import Documents from './components/documents/documents';
import TestHistory from './components/TestHistory';
import TestResults from './components/TestResults';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { DataAboutAudits } from './types';
import { fetchEquipmentData } from './helpers/FetchHelper';
import { urlsGetData } from './static/urls';
import './style.css';

const App = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [dataAboutAudits, setDataAboutAudits] = useState<DataAboutAudits>();

  useEffect(() => {
    let isCancelled = false;

    const fetchData = async (formData: FormData) => {
      try {
        if (isLoading && dataAboutAudits === undefined) {
          for (let i = 0; i < urlsGetData.length && !isCancelled; i++) {
            const url = urlsGetData[i];
            const data = await fetchEquipmentData(url, formData);

            if (data !== null) {
              setDataAboutAudits(data as DataAboutAudits);
              setIsLoading(false);
              break;
            }

            if (urlsGetData[urlsGetData.length - 1] === url) {
              setIsLoading(false);
              setIsError(true);
            }
          }
        }
      } catch (e) {
        setIsError(true);
        setIsLoading(false);
        console.error('Error fetching');
      }
    };

    if (location.search !== '' && !isCancelled) {
      const formData = new FormData();
      formData.append('id', location.search.replace('?', ''));
      formData.append('id', location.search.replace('=', ''));
      fetchData(formData);
    } else {
      setIsError(true);
      setIsLoading(false);
    }

    return () => {
      isCancelled = true;
    };
  }, [dataAboutAudits, isLoading, location.search]);

  const getDataAbout = () => {
    return (dataAboutAudits as DataAboutAudits).equipmentAudits.filter(e => {
      const arr = (dataAboutAudits as DataAboutAudits).equipmentAudits.reduce((accumulator: any, currentValue) => {
        if (
          currentValue.standard !== '' &&
          (!accumulator[currentValue.standard] || accumulator[currentValue.standard] > currentValue.auditDate)
        ) {
          accumulator[currentValue.standard] = {
            auditDate: currentValue.auditDate,
            equipmentAuditId: currentValue.equipmentAuditId,
          };
        }

        return accumulator;
      }, {});

      if (Object.keys(arr).length !== 0) {
        return e.equipmentAuditId === arr[e.standard].equipmentAuditId;
      }
    });
  };

  return (
    <>
      {!isLoading ? (
        <div className="wrapper" id="wrapper">
          <main className="main">
            <section className="section">
              <div className="container">
                <div className="row">
                  {isError && <h1>ERROR!</h1>}
                  {!isError && dataAboutAudits !== undefined && (
                    <div className="col-lg-10 col-lg-offset-1 col-md-12 col-md-offset-0 col-xs-12 col-xs-offset-0 ">
                      <CompanyInfo dataAboutAudits={dataAboutAudits} />
                      {getDataAbout().map((audit, index) => (
                        <TestResults key={`${index}_audit`} equipmentAudit={audit} />
                      ))}

                      {dataAboutAudits.files.length !== 0 && (
                        <Documents files={dataAboutAudits.files} barcodeId={dataAboutAudits.id} />
                      )}
                      <TestHistory audits={dataAboutAudits.equipmentAudits} />
                    </div>
                  )}
                </div>
              </div>
            </section>
          </main>
        </div>
      ) : (
        <img src="img/load.gif" style={{ display: 'block', margin: 'auto' }} alt="load" />
      )}
    </>
  );
};

export default App;
