import { FC, useState } from 'react';
import { DataAboutAudits } from '../../types';
import {
  CloseButton,
  Modal,
  ModalContent,
  ResultBlock,
  ResultBlockBody,
  ResultBlockHead,
  ResultBlockTitle,
  ResultLineAuditorCompany,
  ResultName,
  ResultText,
} from '../style';

interface CompanyInfoProps {
  dataAboutAudits: DataAboutAudits;
}

const CompanyInfo: FC<CompanyInfoProps> = ({ dataAboutAudits }) => {
  const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  const sendReport = () => {
    fetch('https://berater-group-prod.emtu.info/api/equipment/report', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: dataAboutAudits.id,
        name: name,
        description: description,
      }),
    }).catch(() => alert('Vielen Dank für Ihre Meldung. Wir werden uns darum kümmern.'));

    setIsVisibleModal(false);
  };

  return (
    <ResultBlock>
      <ResultBlockHead isFlex={false}>
        <ResultBlockTitle style={{ display: 'inline' }}>{dataAboutAudits.id}</ResultBlockTitle>
        <button style={{ float: 'right' }} onClick={() => setIsVisibleModal(true)}>
          Prüfling melden
        </button>
        <ResultBlockTitle>{dataAboutAudits.clientName}</ResultBlockTitle>
      </ResultBlockHead>
      <ResultBlockBody>
        <ResultLineAuditorCompany>
          <ResultName>Geräteart</ResultName>
          <ResultText>{dataAboutAudits.equipmentType}</ResultText>
        </ResultLineAuditorCompany>
        {dataAboutAudits.manufacturer !== null && (
          <ResultLineAuditorCompany>
            <ResultName>Hersteller</ResultName>
            <ResultText>{dataAboutAudits.manufacturer}</ResultText>
          </ResultLineAuditorCompany>
        )}
        <ResultLineAuditorCompany>
          <ResultName>Adresse</ResultName>
          <ResultText>
            {dataAboutAudits.street} {dataAboutAudits.house}
          </ResultText>
        </ResultLineAuditorCompany>
        <ResultLineAuditorCompany>
          <ResultName></ResultName>
          <ResultText>
            {dataAboutAudits.zip} {dataAboutAudits.city}
          </ResultText>
        </ResultLineAuditorCompany>
        <ResultLineAuditorCompany>
          <ResultName></ResultName>
          <ResultText>{dataAboutAudits.country}</ResultText>
        </ResultLineAuditorCompany>
        {dataAboutAudits.clientLogo !== null && (
          <ResultLineAuditorCompany>
            <ResultName></ResultName>
            <ResultText>
              <img src={dataAboutAudits.clientLogo} alt="Prüfdienstleister" />
            </ResultText>
          </ResultLineAuditorCompany>
        )}
      </ResultBlockBody>
      {isVisibleModal && (
        <Modal>
          <ModalContent>
            <CloseButton onClick={() => setIsVisibleModal(false)}>&times;</CloseButton>
            <p>
              <b>Ihr Name</b>
            </p>
            <p>
              <input id="reporter" type="text" width="50%" onChange={e => setName(e.target.value)} />
            </p>
            <p>
              <b>Beschreiben Sie den Fehler</b>
            </p>
            <p>
              <textarea
                rows={10}
                style={{ width: '100%' }}
                name="text"
                onChange={e => setDescription(e.target.value)}
              />
            </p>
            <button
              disabled={name.trim() === '' || description.trim() === ''}
              onClick={sendReport}
              style={{ width: '40%' }}
            >
              Absenden
            </button>
          </ModalContent>
        </Modal>
      )}
    </ResultBlock>
  );
};

export default CompanyInfo;
